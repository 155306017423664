@import "./variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;700&display=swap");

html {
	width: 100%;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	margin:0;
	-webkit-tap-highlight-color:rgba(0, 0, 0, 0);
	background-color: #F5F5F5;
}

* {
	box-sizing: border-box;
	outline: none;
	vertical-align: top;
}

#root {
	position: relative;
	height: 100%;
	overflow-x: hidden;
}